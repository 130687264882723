var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-danger ml-1"},[_vm._v("Job Portal")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"7"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)},"keydown":function($event){return _vm.form.onKeydown($event)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"login-name"}},[_c('b-form-input',{attrs:{"id":"login-name","name":"login-name","placeholder":"Enter Your Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('has-error',{attrs:{"form":_vm.form,"field":"name"}})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"login-phone"}},[_c('b-form-input',{attrs:{"id":"login-phone","name":"login-phone","placeholder":"Enter Your Phone Number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('has-error',{attrs:{"form":_vm.form,"field":"phone"}})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('b-form-input',{class:{
                'is-invalid border-danger': _vm.form.errors.has('email'),
              },attrs:{"id":"login-email","name":"login-email","placeholder":"john@example.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('has-error',{attrs:{"form":_vm.form,"field":"email"}})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",class:{
                  'is-invalid border-danger': _vm.form.errors.has('password'),
                },attrs:{"id":"login-password","type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('has-error',{attrs:{"form":_vm.form,"field":"password"}})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password_confirmation"}},[_vm._v("Password Confirmation")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",class:{
                  'is-invalid border-danger': _vm.form.errors.has(
                    'password_confirmation'
                  ),
                },attrs:{"id":"login-password_confirmation","type":_vm.passwordFieldType,"name":"login-password_confirmation","placeholder":"············"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('has-error',{attrs:{"form":_vm.form,"field":"password_confirmation"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"danger","block":"","disabled":_vm.form.busy}},[_vm._v(" Sign Up ")])],1),_c('router-link',{attrs:{"to":"/login"}},[_c('b-button',{staticClass:"mt-2",attrs:{"block":""}},[_vm._v(" Sign In ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }