<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-danger ml-1">Job Portal</h2>
      </b-link>
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="register"
            @keydown="form.onKeydown($event)"
          >
            <!-- Name -->
            <b-form-group label="Name" label-for="login-name">
              <b-form-input
                id="login-name"
                v-model="form.name"
                name="login-name"
                placeholder="Enter Your Name"
              />
              <has-error :form="form" field="name"></has-error>
            </b-form-group>
            <!-- phone -->
            <b-form-group label="Phone" label-for="login-phone">
              <b-form-input
                id="login-phone"
                v-model="form.phone"
                name="login-phone"
                placeholder="Enter Your Phone Number"
              />
              <has-error :form="form" field="phone"></has-error>
            </b-form-group>
            <!-- email -->
            <b-form-group label="Email" label-for="login-email">
              <b-form-input
                id="login-email"
                v-model="form.email"
                name="login-email"
                placeholder="john@example.com"
                :class="{
                  'is-invalid border-danger': form.errors.has('email'),
                }"
              />
              <has-error :form="form" field="email"></has-error>
            </b-form-group>
            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password"
                  v-model="form.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                  :class="{
                    'is-invalid border-danger': form.errors.has('password'),
                  }"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <has-error :form="form" field="password"></has-error>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password_confirmation"
                  >Password Confirmation</label
                >
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password_confirmation"
                  v-model="form.password_confirmation"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password_confirmation"
                  placeholder="············"
                  :class="{
                    'is-invalid border-danger': form.errors.has(
                      'password_confirmation'
                    ),
                  }"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <has-error :form="form" field="password_confirmation"></has-error>
            </b-form-group>
            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="danger"
              block
              :disabled="form.busy"
            >
              Sign Up
            </b-button>
          </b-form>
          <router-link to="/login">
            <b-button class="mt-2" block> Sign In </b-button>
          </router-link>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    HasError,
    AlertError,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: new Form({
        name: null,
        phone: null,
        email: null,
        password: null,
        password_confirmation: null,
      }),
      message: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    register() {
      this.form
        .post("/candidate/register", this.form)
        .then((res) => {
          if (res.data) {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("user_name", res.data.data.name);
            localStorage.setItem("email", res.data.data.email);
            localStorage.setItem("phone", res.data.data.phone);
            localStorage.setItem("photo", res.data.data.photo);
            localStorage.setItem("onboard", res.data.data.onboard_status);
            this.$store.dispatch("setToken", res.data.data);
            this.s(res.data.message);
            this.$router.push({
              name: "verify",
            });
          }
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
